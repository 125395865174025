import React from "react"
import 'react-slick'
import { Waypoint } from 'react-waypoint';

import {handleWaypointIn} from "../utilities/waypoint"

import Layout from "../components/layout/Layout"
import PortfolioFilter from "../components/portfolio/PortfolioFilter"
import ContactSection from "../components/contact/ContactSection"
import SEO from "../components/seo"

const Portfolio = () => {
    return ( 
        <div className="portfolio-page">
            <Layout>
                <SEO 
                    title="Web Development Portfolio"
                    description="Chris Bartlett's Web Development Portfolio" 
                />	               
                <section className="page-header">
                    <div className="section-top-wrapper">
                        <Waypoint onEnter={() => handleWaypointIn('title-text', 'tracking-in-expand')}>         
                            <h1 id="title-text">Portfolio</h1> 
                        </Waypoint>                       
                    </div>           
                </section>    
                
                <PortfolioFilter />

                <ContactSection/>
            </Layout> 
        </div>
    )
}

export default Portfolio