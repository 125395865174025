import React, { useState, useEffect }  from "react"
import Img from "gatsby-image"
import { StaticQuery, Link } from 'gatsby'

const PortfolioFilter = () => {
    // store the isotope object in one state
    const [isotope, setIsotope] = useState(null);

    // store the filter keyword in another state
    const [filterKey, setFilterKey] = useState("*");  

    // filter header
    const [filterHeader, setFilterHeader] = useState("*");  

    // setup intial isotop 
    useEffect(() => {
        if (typeof window !== `undefined`) {
            const Isotope = require("isotope-layout/js/isotope")
            setIsotope(
                new Isotope(".filter-container", {
                    itemSelector: ".grid-item",
                    layoutMode: 'fitRows',
                })
            )
        }
    }, [])
  
    useEffect(() => {
        if (isotope) {
            filterKey === "*"
            ? isotope.arrange({ filter: `*` })
            : isotope.arrange({ filter: `.${filterKey}` })
        }
    }, [isotope, filterKey])


    const setFilterLink = (e, filter) => {
        e.preventDefault()
        setFilterKey(filter)
    }    

    const setFilterButton = (e, filter) => {
        e.preventDefault()
        setFilterHeader(filter)
        setFilterKey('*')
        
    }        

    return ( 
        <StaticQuery
        query={graphql `
        query {
            allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___title]}, filter: {frontmatter: {post_type: {eq: "portfolio"}}}) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                  title
                  post_type
                  filters
                  thumbnail {
					childImageSharp {
						fixed(height: 680, quality: 90) {
							...GatsbyImageSharpFixed_withWebp
						}						
					}                      
                  }            
              }
            }
          }
        }
      }
      `}
        render={data => {
            let portfolios = data.allMarkdownRemark.edges

            return (
            <>
                <section className="portfolio-stack-header">                         
                    <div className="portfolio-filters-desktop-header">
                        <div className="filters">
                            <ul>
                                <li><button className={`${filterHeader === '*'  ? "active" : ''}`} onClick={(e) => setFilterButton(e, '*')}>All</button></li>
                                <li><button className={`${filterHeader === 'software'  ? "active" : ''}`} onClick={(e) => setFilterButton(e, 'software')}>Software</button></li>
                                <li><button className={`${filterHeader === 'theme'  ? "active" : ''}`} onClick={(e) => setFilterButton(e, 'theme')}>Theme</button></li>
                                <li><button className={`${filterHeader === 'development'  ? "active" : ''}`} onClick={(e) => setFilterButton(e, 'development')}>Development</button></li>
                                <li><button className={`${filterHeader === 'client'  ? "active" : ''}`} onClick={(e) => setFilterButton(e, 'client')}>Type</button></li>
                            </ul>
                        </div>

                        <div className="filters-sub">
                            <div className={`portfolio-filters-desktop ${filterHeader === '*'  ? "active" : ''}`}>
                                <h6>ALL</h6>       
                                <p>Please select a filter to refine the search</p>                
                            </div>


                            <div className={`portfolio-filters-desktop ${filterHeader === 'software'  ? "active" : ''}`}>
                                <h6>Software</h6>       
                                <div className="button-wrappers split">
                                    <button className={`${filterKey === 'api'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'api')}>API Work</button>
                                    <button className={`${filterKey === 'cms'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'cms')}>CMS</button>
                                    <button className={`${filterKey === 'drupal'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'drupal')}>Drupal</button>
                                    <button className={`${filterKey === 'ecommerce'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'ecommerce')}>Ecommerce</button>
                                    <button className={`${filterKey === 'gatsby'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'gatsby')}>Gatsby</button>
                                    <button className={`${filterKey === 'laravel'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'laravel')}>Laravel</button>
                                    <button className={`${filterKey === 'magento'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'magento')}>Magento</button>
                                    <button className={`${filterKey === 'rails'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'rails')}>Rails</button>
                                    <button className={`${filterKey === 'software'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'software')}>Software</button>
                                    <button className={`${filterKey === 'static'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'static')}>Static (HTML)</button>
                                    <button className={`${filterKey === 'woocommerce'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'woocommerce')}>Woocommerce</button>
                                    <button className={`${filterKey === 'wordpress'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'wordpress')}>Wordpress</button>
                                </div>                 
                            </div>


                            <div className={`portfolio-filters-desktop ${filterHeader === 'theme'  ? "active" : ''}`}>
                                <h6>Theme</h6>
                                <div className="button-wrappers">
                                    <button className={`${filterKey === 'custom'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'custom')}>Custom</button>
                                    <button className={`${filterKey === 'elementor'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'elementor')}>Elementor Page Builder</button>
                                    <button className={`${filterKey === 'salient'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'salient')}>Salient Theme</button>
                                </div>

        
                            </div>
                            
                            <div className={`portfolio-filters-desktop ${filterHeader === 'development'  ? "active" : ''}`}>
                                <h6>Development</h6>
                                <div className="button-wrappers">
                                    <button className={`${filterKey === 'backend'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'backend')}>Backend</button>
                                    <button className={`${filterKey === 'frontend'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'frontend')}>Frontend</button>                        
                                </div>
                            </div>
                            
                            <div className={`portfolio-filters-desktop ${filterHeader === 'client'  ? "active" : ''}`}>
                                <h6>Type</h6>
                                <div className="button-wrappers split">
                                    <button className={`${filterKey === 'academic'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'academic')}>Academic</button>
                                    <button className={`${filterKey === 'arts'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'arts')}>Arts</button>
                                    <button className={`${filterKey === 'dashboard'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'dashboard')}>Dashboard</button>
                                    <button className={`${filterKey === 'financial'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'financial')}>Financial</button>
                                    <button className={`${filterKey === 'food'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'food')}>Food</button>
                                    <button className={`${filterKey === 'hospitality'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'hospitality')}>Hospitality</button>
                                    <button className={`${filterKey === 'insurance'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'insurance')}>Insurance</button>
                                    <button className={`${filterKey === 'jewellery'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'jewellery')}>Jewellery</button>
                                    <button className={`${filterKey === 'media'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'media')}>Media</button>
                                    <button className={`${filterKey === 'medicine'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'medicine')}>Medicine</button>
                                    <button className={`${filterKey === 'pr'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'pr')}>PR</button>
                                    <button className={`${filterKey === 'technology'  ? "active" : ''}`} onClick={(e) => setFilterLink(e, 'technology')}>Technology</button>
                                </div>
                            </div> 
                        </div>  
                    </div>
                      
                   
                </section>    
                
                <section id="portfolio-stack" className="filter-container">
                    <div className="grid">
                    {                  
                        portfolios.map((portfolio, index) => {
                        return (
                            <div key={index} className={`grid-item ${portfolio.node.frontmatter.filters} ${filterKey !== '*'  ? "active" : null}`}>

                                <Link 
                                    key={index}
                                    to={`/${portfolio.node.frontmatter.post_type}${portfolio.node.fields.slug}`}>
                                    {portfolio.node.frontmatter.thumbnail && 
                                        <div className="portfolio-image">
                                            <Img 
                                                fixed={portfolio.node.frontmatter.thumbnail.childImageSharp.fixed} 
                                                alt={portfolio.node.frontmatter.title} 
                                                key="header-thumb" 
                                                objectFit="cover"
                                                objectPosition="top center"																						
                                            />                                        
                                        </div>
                                    }
                                    <div className="portfolio-content">
                                        <h3 className="text">{portfolio.node.frontmatter.title}</h3> 
                                    </div>                            
                                </Link> 
                            </div>
                        )})            
                        
                    }
                    </div>
                </section>
                <div className="spacer"></div>
                            
            </>
        )}}
      />
    )
}
  

export default PortfolioFilter